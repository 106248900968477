import React, {Component} from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Ajay_pic from "../images/team/Ajay.png"
import Aneesh_pic from "../images/team/aneesh_s.png"
import Aryan_pic from "../images/team/aryan_aj.png"
import Avneet_pic from "../images/team/Avneet.png"
import Balbir_pic from "../images/team/Balbir.png"
import Carlos_pic from "../images/team/Carlos.png"
import Charanjeev_pic from "../images/team/Charanjeev.png"
import Deep_pic from "../images/team/Deep.png"
import Dheeraj_pic from "../images/team/Dheeraj.png"
import Gaurav_pic from "../images/team/Gaurav.png"
import Girish_pic from "../images/team/Girish.png"
import Gopalsingh_pic from "../images/team/Gopal-Singh.png"
import Harleen_pic from "../images/team/Harleen.png"
import Jyoti_pic from "../images/team/Jyoti.png"
import Ketaky_pic from "../images/team/Ketaky.png"
import Kunal_pic from "../images/team/Kunal.png"
import Mannu_pic from "../images/team/Mannu.png"
import Nida_pic from "../images/team/Nida.png"
import Paawan_pic from "../images/team/paawan_sharma.png"
import Pancham_pic from "../images/team/Pancham.png"
import Parth_pic from "../images/team/Parth.png"
import Pooja_pic from "../images/team/pooja.png"
import Rajender_pic from "../images/team/rajender_kumar.png"
import Rajiv_pic from "../images/team/Rajiv.png"
import Ravinder_pic from "../images/team/ravinder.png"
import Sarthak_pic from "../images/team/Sarthak.png"
import Shubham_pic from "../images/team/Shubham.png"
import Simoni_pic from "../images/team/simoni_patel.png"
import Sumit_pic from "../images/team/Sumit.png"
import Tapam_pic from "../images/team/Tapam.png"
import Vivek_pic from "../images/team/Vivek.png"
import Yash_pic from "../images/team/Yash.png"
import Jolly_pic from "../images/team/Jolly.png"
import Priceqa_pic from "../images/team/Prince_qa.png"
import Taniya_pic from "../images/team/taniya.png"
import Ritika1_pic from "../images/team/ritika1.png"
import Aamir_pic from "../images/team/aamir.png"
import Sahil_pic from "../images/team/Sahil_react.png"
import NidhiL_pic from "../images/team/nidhi_lakh.png"
import ShwetaQA_pic from "../images/team/Shweta.png"
import Aravind_pic from "../images/team/Aravind-B.png"
import {Helmet} from "react-helmet"


export default class Container extends Component {

  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>CueForGood Team - the specialists helping businesses become a Force for Good</title>
          <meta name="description"
                content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
              <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
                <link rel="canonical" href={this.state.url}/>
                <meta property="og:site_name" content="CueForGood"/>
                <meta property="fb:app_id" content="289086684439915"/>
                <meta property="og:url" content={this.state.url}/>
                <meta property="og:title"
                      content="CueForGood Team - the specialists helping businesses become a Force for Good"/>
                <meta property="og:description"
                      content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites."/>
                <meta property="og:image" content="https://www.CueForGood.com/images/cb.jpg"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:description"
                      content="CueForGood has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites."/>
                <meta name="twitter:title"
                      content="CueForGood Team - the specialists helping businesses become a Force for Good"/>
                <meta name="twitter:site" content="@CueForGood"/>
                <meta name="twitter:image" content="https://www.CueForGood.com/images/cb.jpg"/>
        </Helmet>
        <section className="ser_sec_row team-page page-header">
          <div className="container">
            <div className="main-heading">
              <h2 className="heading_main">Meet our team</h2>
              <p>
              Since our inception, our success has been a result of our exceptional team! Here is the CueTeam who work their magic in eCommerce and allow us to serve our clients.
               </p>
            </div>

            <div className="team-members">
              <div className="member-sec">
                <div className="member-img">
                  <img src={Aamir_pic} alt="Aamir CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Aamir Khan</h4>
                </div>
                <div className="member-pro">Jr. System Administrator</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ajay_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Ajay Kumar</h4>
                </div>
                <div className="member-pro">Office Support</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Aneesh_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Aneesh Sharma</h4>
                </div>
                <div className="member-pro">Intern - ESG</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Aravind_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Aravindkumar B</h4>
                </div>
                <div className="member-pro">Sr. Shopify Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Aryan_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Aryan</h4>
                </div>
                <div className="member-pro">Office Support</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Avneet_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Avneet Jirman</h4>
                </div>
                <div className="member-pro">Co-Founder</div>
              </div>
          
              <div className="member-sec">
                <div className="member-img">
                  <img src={Balbir_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Balbir Singh</h4>
                </div>
                <div className="member-pro">UI/UX Designer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Carlos_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Carlos</h4>
                </div>
                <div className="member-pro">Chief Cuddles Officer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Charanjeev_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Charanjeev Singh</h4>
                </div>
                <div className="member-pro">Digital Marketing Analyst</div>
              </div>
           
              <div className="member-sec">
                <div className="member-img">
                  <img src={Deep_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Deep Sharma</h4>
                </div>
                <div className="member-pro">Sr. Accountant</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Dheeraj_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Dheeraj Kumar</h4>
                </div>
                <div className="member-pro">Linux System Administrator</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Gaurav_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Gaurav Jafrain</h4>
                </div>
                <div className="member-pro">Team Lead - Quality Analyst</div>
              </div>
            

              <div className="member-sec">
                <div className="member-img">
                  <img src={Girish_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Girish Tiwari</h4>
                </div>
                <div className="member-pro">
                Division Head-eCommerce Development
                </div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Gopalsingh_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Gopal Singh</h4>
                </div>
                <div className="member-pro">Chef</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Harleen_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Harleen Kaur Sandhu</h4>
                </div>
                <div className="member-pro">Organic Search Strategist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Jolly_pic} alt="Jolly CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Jolly Sandhu</h4>
                </div>
                <div className="member-pro">Front End Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Jyoti_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Jyoti</h4>
                </div>
                <div className="member-pro">Sr. Web Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ketaky_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Ketaky Sharma</h4>
                </div>
                <div className="member-pro">Division Head - People & Culture</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Kunal_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Kunal Mahajan</h4>
                </div>
                <div className="member-pro">Digital Advertising Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Mannu_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Mannu Dhiman</h4>
                </div>
                <div className="member-pro">Organic Search Strategist</div>
              </div> 
               <div className="member-sec">
                <div className="member-img">
                  <img src={Nida_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Nida Danish</h4>
                </div>
                <div className="member-pro">Content Strategist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={NidhiL_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Nidhi Lakhani</h4>
                </div>
                <div className="member-pro">Sr. Shopify Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Paawan_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Paawan Sharma</h4>
                </div>
                <div className="member-pro">Finance & Operations Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Pancham_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Pancham Prashar</h4>
                </div>
                <div className="member-pro">Co-Founder</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Parth_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Parth Gautam</h4>
                </div>
                <div className="member-pro">Front End Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Pooja_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Pooja Rathore</h4>
                </div>
                <div className="member-pro">Sr. Talent Acquisition Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Priceqa_pic} alt="prince CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Prince Sethi</h4>
                </div>
                <div className="member-pro">Jr. Quality Analyst</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Rajender_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Rajender Kumar</h4>
                </div>
                <div className="member-pro">Sr. Digital Advertising Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Rajiv_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Rajiv Singha</h4>
                </div>
                <div className="member-pro">Division Head - Organic Search</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ravinder_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Ravinder Singh</h4>
                </div>
                <div className="member-pro">Front End Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ritika1_pic} alt="Ritika CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Ritika PRASHAR</h4>
                </div>
                <div className="member-pro">Finance Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Sahil_pic} alt="Sahil CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Sahil</h4>
                </div>
                <div className="member-pro">Web Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Sarthak_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Sarthak Aggarwal</h4>
                </div>
                <div className="member-pro">Co-Founder</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Shubham_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Shubham Jain</h4>
                </div>
                <div className="member-pro">Divison Head - Shopify</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={ShwetaQA_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Shweta Rohilla</h4>
                </div>
                <div className="member-pro">Quality Analyst</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Simoni_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Simoni Patel</h4>
                </div>
                <div className="member-pro">Sr. Shopify Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Sumit_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Sumit Sharma</h4>
                </div>
                <div className="member-pro">Sr. Web Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Taniya_pic} alt="Taniya  CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Tanya</h4>
                </div>
                <div className="member-pro">Intern- Frontend Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Tapam_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Tapam Jaswal</h4>
                </div>
                <div className="member-pro">Organic Search Strategist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Vivek_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Vivek Puri</h4>
                </div>
                <div className="member-pro">Manager - Digital Advertising</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Yash_pic} alt="CueForGood" />
                </div>
                <div className="member-name">
                  <h4>Yash Pal</h4>
                </div>
                <div className="member-pro">
                  Division Head, Digital Marketing (CSE)
                </div>
              </div>
            </div>
            <div className="main-heading btmm-team">
              <h2 className="heading_main">Join our team</h2>
              <p>
                We’re always on the lookout for smart, purposeful, and driven people  to be part of our growing team.
                 Check out the <Link to="/career/" className="simple_anchor">careers </Link> 
                page for open positions.
              </p>
            </div>
          </div>
        </section>

        <Clutch />
      </Layout>
    )

  }
}

